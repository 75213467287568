import moment from "moment";
import { API } from "aws-amplify";

import { Schedule, UpdateScheduleInput } from "API";
import { getSchedule } from "graphql/queries";
import { updateSchedule } from "graphql/mutations";

// GQLを使ってscheduleをIDで取得する
export const getScheduleById = async (id: string): Promise<Schedule> => {
  const s = (
    await API.graphql({
      query: getSchedule,
      variables: { id },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.getSchedule;

  return {
    __typename: "Schedule",
    id: s.id,
    projectId: s.projectId,
    name: s.name,
    note: s.note,
    stockingDate: s.stockingDate,
    packagingDate: s.packagingDate,
    shippingDate: s.shippingDate,
    shipType: s.shipType,
    cutDate: s.cutDate,
    createdAt: s.createdAt,
    updatedAt: s.updatedAt,
  };
};

// GQLを使ってscheduleを更新する
export const updateScheduleByInput = async (
  input: UpdateScheduleInput
): Promise<void> => {
  return await API.graphql({
    query: updateSchedule,
    variables: { input },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};

// targetがどこのphaseの日にちか確認する
export const getPhase = (
  schedule: {
    stockingDate: string | null;
    packagingDate: string | null;
    shippingDate: string | null;
    cutDate: string | null;
  },
  target: string
): "stock" | "pack" | "ship" | "cut" | "other" => {
  const { stockingDate, packagingDate, shippingDate, cutDate } = schedule;
  const dates = [stockingDate, packagingDate, shippingDate, cutDate];

  // datesでnullのものを除外、型もstring[]にする
  const filteredDates = dates.filter((date) => date !== null) as string[];
  // 各日付のどの期間に含まれているか確認
  // stockingDateからpackagingDateの場合はpack, packagingDateからshippingDateの場合はship
  // shippingDateからcutDateの場合はcut, それ以降はcutになる(cutはない場合もあるのでその場合はshipまで)
  let result = -1;
  // idx=0は(入荷)範囲がないため除外
  for (let idx = 1; idx < filteredDates.length; idx++) {
    const startDate = filteredDates[idx - 1];
    const endDate = filteredDates[idx];

    // 含まれていればひとつ前のidx。
    if (target >= startDate && target < endDate) {
      result = idx - 1;
      break;
    }
    // 最後だったらそのindexを返す
    if (idx === filteredDates.length - 1) {
      result = idx;
      break;
    }
  }
  // resultの値でどのフェーズか判定
  // 案件タイプ=その他の場合、梱包日はないためステータスが入れ替わる。
  // ここでpackを返すと梱包日が設定されてしまいスケジュール画面に出てしまう
  switch (result) {
    case 0:
      return "stock";
    case 1:
      return packagingDate ? "pack" : "ship";
    case 2:
      return packagingDate ? "ship" : "cut";
    case 3:
      return packagingDate ? "cut" : "other";
    default:
      return "other";
  }
};

// toDateを以下の観点でvalidateする
// - 前後のphaseに含まれているか(isInclude)
// - 移動元が終点だったら開始日を、始点だったら終了日を越してはいけない(isValid)
// phaseによっては日にちが重なっていたりするのでそれも考慮している
export const validateToDate = (
  schedule: {
    stockingDate: string | null;
    packagingDate: string | null;
    shippingDate: string | null;
    cutDate: string | null;
  },
  toDate: string,
  phase: "stock" | "pack" | "ship" | "cut"
): boolean => {
  const { stockingDate, packagingDate, shippingDate, cutDate } = schedule;

  if (phase === "stock") {
    let isValid =
      // 過去方向へは制限なし
      // 未来方向へは出荷日まで
      packagingDate !== null
        ? moment(toDate).isSameOrBefore(packagingDate)
        : moment(toDate).isSameOrBefore(shippingDate);
    return isValid;
  } else if (phase === "pack") {
    let isValid =
      // 過去方向へは梱包日=入荷日なら制限なし、≠なら入荷日まで
      (stockingDate === packagingDate
        ? true
        : moment(toDate).isSameOrAfter(stockingDate)) &&
      // 未来方向へは出荷日まで
      moment(toDate).isSameOrBefore(shippingDate);
    return isValid;
  } else if (phase === "ship") {
    let isValid =
      // 過去方向へは入荷日=出荷日なら制限なし、≠なら梱包日または入荷日まで
      // 入荷日と同じなら一緒に移動する
      (stockingDate === shippingDate
        ? true
        : moment(toDate).isSameOrAfter(stockingDate)) &&
      // 梱包日と同じなら一緒に移動する。案件タイプ=その他の場合は検査しない
      (packagingDate === null || packagingDate === shippingDate
        ? true
        : moment(toDate).isSameOrAfter(packagingDate)) &&
      // 梱包日と同じだが入荷日と異なる場合は入荷日まで
      (packagingDate !== null &&
      packagingDate === shippingDate &&
      stockingDate !== shippingDate
        ? moment(toDate).isSameOrAfter(stockingDate)
        : true) &&
      // 未来方向へはCUT日まで。CUT日が無ければ制限なし
      (!cutDate ? true : moment(toDate).isSameOrBefore(cutDate));
    return isValid;
  } else if (phase === "cut") {
    let isValid =
      // 過去方向へは同じではない日付のフェーズまで
      // 入荷日と同じなら一緒に移動する
      (stockingDate === cutDate
        ? true
        : moment(toDate).isSameOrAfter(stockingDate)) &&
      // 梱包日と同じなら一緒に移動する。案件タイプ=その他の場合は検査しない
      (packagingDate === null || packagingDate === cutDate
        ? true
        : moment(toDate).isSameOrAfter(packagingDate)) &&
      // 梱包日と同じだが入荷日と異なる場合は入荷日まで
      (packagingDate !== null &&
      packagingDate === cutDate &&
      stockingDate !== cutDate
        ? moment(toDate).isSameOrAfter(stockingDate)
        : true) &&
      // 出荷日と同じではない場合は出荷日まで
      (shippingDate === cutDate
        ? true
        : moment(toDate).isSameOrAfter(shippingDate));
    // 未来方向に制限なし
    return isValid;
  }
  return false;
};

// scheduleを移動さきによって更新する
export const getUpdatedSchedule = async (
  fromDate: string,
  toDate: string,
  row: any
): Promise<Schedule> => {
  // どのphaseがどの日にちからどの日にちに動こうとしているか取得

  // fromDateとtoDateが同じなら何もしない
  if (fromDate === toDate) return Promise.reject();

  // 移動が過去方向か未来方向か確認
  const isFuture = moment(fromDate).isBefore(toDate);

  // fromDateからどのフェーズか確認
  const phase = getPhase(row, fromDate);
  if (phase === "other") return Promise.reject();

  // toDateは前後のphaseの期間でないといけない
  if (!validateToDate(row, toDate, phase)) {
    return Promise.reject();
  }

  // 変更対象のschedule取得
  const targetSchedule = await getScheduleById(row.id);
  if (targetSchedule === undefined) {
    return Promise.reject();
  }

  // 以下各phaseの日にちを更新していく
  /*
    - phaseの重なりについて
      - 未来方向への移動は見えているphase(一番後のphase)のみ更新
      - 過去方向への移動は全てまとめて更新
    - 対象が開始日の場合…そのphaseの日にちを更新、ただし前のphaseがある場合はその日にちも更新
    - 対象が終了日の場合…その後のphaseの日にちを更新
    - 対象が開始日であり終了日の場合…そのphaseの日にちを更新、未来 or 過去方向によって更新が必要なphaseを更新する
  */

  if (phase === "stock") {
    // 過去方向へは入荷日のみ移動
    targetSchedule["stockingDate"] = toDate;
    // 未来方向へは重なる日付をそれぞれ移動
    if (
      targetSchedule["cutDate"] !== null &&
      targetSchedule["cutDate"] === targetSchedule["stockingDate"]
    ) {
      targetSchedule["cutDate"] = moment(toDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
    if (targetSchedule["shippingDate"] === targetSchedule["stockingDate"]) {
      targetSchedule["shippingDate"] = moment(toDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
    if (
      targetSchedule["packagingDate"] !== null &&
      targetSchedule["packagingDate"] === targetSchedule["stockingDate"]
    ) {
      targetSchedule["packagingDate"] = moment(toDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
  } else if (phase === "pack") {
    // 過去方向へは梱包日と重なる入荷日を移動
    if (
      targetSchedule["packagingDate"] === targetSchedule["stockingDate"] &&
      !isFuture
    ) {
      targetSchedule["stockingDate"] = toDate;
    }
    targetSchedule["packagingDate"] = toDate;
    // 未来方向へは後ろにあるCUT日・出荷日を移動する
    if (
      targetSchedule["cutDate"] !== null &&
      targetSchedule["cutDate"] === targetSchedule["packagingDate"]
    ) {
      targetSchedule["cutDate"] = moment(toDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
    if (targetSchedule["shippingDate"] === targetSchedule["packagingDate"]) {
      targetSchedule["shippingDate"] = moment(toDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
  } else if (phase === "ship") {
    // 過去方向へは出荷日と同じ入荷日・梱包日を移動
    if (
      targetSchedule["packagingDate"] !== null &&
      targetSchedule["packagingDate"] === targetSchedule["shippingDate"] &&
      !isFuture
    ) {
      targetSchedule["packagingDate"] = toDate;
    }
    if (
      targetSchedule["stockingDate"] === targetSchedule["shippingDate"] &&
      !isFuture
    ) {
      targetSchedule["stockingDate"] = toDate;
    }
    targetSchedule["shippingDate"] = toDate;
    // 未来方向へはCUT日を移動
    if (
      targetSchedule["cutDate"] !== null &&
      targetSchedule["cutDate"] === targetSchedule["shippingDate"]
    ) {
      targetSchedule["cutDate"] = moment(toDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
  } else if (phase === "cut") {
    // 過去方向へは同じ入荷日・梱包日・出荷日を移動する
    if (
      targetSchedule["shippingDate"] === targetSchedule["cutDate"] &&
      !isFuture
    ) {
      // CUT日は見えないため、過去方向も未来方向も出荷日と同じ動きにする
      targetSchedule["shippingDate"] = toDate;
    }
    if (
      targetSchedule["packagingDate"] !== null &&
      targetSchedule["packagingDate"] === targetSchedule["cutDate"] &&
      !isFuture
    ) {
      targetSchedule["packagingDate"] = toDate;
    }
    if (
      targetSchedule["stockingDate"] === targetSchedule["cutDate"] &&
      !isFuture
    ) {
      targetSchedule["stockingDate"] = toDate;
    }
    // CUT日と同じ日であれば出荷日を移動
    targetSchedule["cutDate"] = toDate;
  }

  const input: UpdateScheduleInput = {
    id: targetSchedule.id,
    projectId: targetSchedule.projectId,
    name: targetSchedule.name,
    m3: targetSchedule.m3,
    case: targetSchedule.case,
    shipType: targetSchedule.shipType,
    stockingDate: targetSchedule.stockingDate,
    packagingDate: targetSchedule.packagingDate,
    shippingDate: targetSchedule.shippingDate,
    cutDate: targetSchedule.cutDate,
    numImgs: targetSchedule.numImgs,
    isShipped: targetSchedule.isShipped,
  };
  await updateScheduleByInput(input);
  const resultSchedule = await getScheduleById(row.id);
  if (resultSchedule === undefined) {
    return Promise.reject();
  }
  return resultSchedule;
};
